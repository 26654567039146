import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Space,
  notification,
  Spin,
  Row,
  Col,
  Switch,
  Select,
} from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  WarningOutlined,
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import './index.scss';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';
import { BASE_URL, MASTER_DATA_URL, OIDC } from 'services/config';

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"
      fill="white"
    />
  </svg>
);

const CreateProposalSubmissionStepThree = () => {
  const params = useParams();
  let navigate = useNavigate();
  let proposal;

  const [form] = Form.useForm();
  const Option = Select.Option;
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const requiredField = [{ required: true, message: 'Wajib Diisi' }];
  const [loading, setLoading] = useState(false);
  const [colKL, setColKL] = useState(true);
  const [colDonor, setColDonor] = useState(true);
  const [emails, setEmails] = useState([]);
  const [donors, setDonors] = useState([]);
  const proposalId = params?.id;

  const [listDirectorate, setListDirectorate] = useState([]);
  const [listKL, setListKL] = useState([]);
  const [listSF, setListSF] = useState([]);

  useEffect(() => {
    const element = document.getElementById('top');
    element.scrollIntoView();

    loadDetailDataProposal();
    loadListDirectorate();
    loadListKL();
    loadListSF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadListSF = () => {
    axios
      .get(`${MASTER_DATA_URL}/SourceofFunds/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListSF(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListDirectorate = () => {
    axios
      .get(
        `https://bpldh-api-v2.groot.id/api/sdm/v1/common/employee-directorates?paginate=false`
      )
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.name,
              label: item.name,
              code: item.code,
              id: item.id,
            });
          });

          setListDirectorate(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKL = () => {
    axios
      .post(
        `https://bpldh-api-v2.groot.id/api/user-management/v1/EmployeeKL/datatable`,
        {}
      )
      .then((res) => {
        if (res.data) {
          let data = res.data.data.data || [];
          let unique = [...new Set(data.map((item) => item.directorat))];

          let arr = [];

          unique.forEach((item, idx) => {
            arr.push({
              key: idx,
              value: item,
              label: item,
              code: '00' + idx,
              id: '120b239d-9752-4177-8609-9a0209101617',
            });
          });

          setListKL(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDetailDataProposal = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/proposal/${proposalId}`)
      .then((res) => {
        proposal = res.data.result;
        setLoading(false);
        if (proposal.klDirectorate) {
          let arr = [];

          proposal.klDirectorate?.kl.forEach((item, idx) => {
            arr.push({
              kl: item,
              directorate: proposal.klDirectorate?.directorate[idx],
            });
          });

          form.setFieldsValue({ klDirectorate: arr || [{}] });
        } else {
          form.setFieldsValue({ klDirectorate: [{}] });
        }

        if (
          Object.prototype.toString.call(proposal.donor) !== '[object Array]'
        ) {
          form.setFieldsValue({ donor: [proposal.donor] });
        } else {
          form.setFieldsValue({ donor: proposal.donor });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const normalizePayload = (formValues) => {
    let data = JSON.parse(JSON.stringify(formValues));
    const dataDonor = [];

    if (Object.prototype.toString.call(data.donor) !== '[object Array]') {
      data.donor = [data.donor];
    }

    let _klDirectorate = { kl: [], directorate: [] };
    data?.klDirectorate.forEach((item) => {
      _klDirectorate.kl.push(item.kl);
      _klDirectorate.directorate.push(item.directorate);
    });

    data.klDirectorate = _klDirectorate;

    if (data?.donor) {
      data.donor?.forEach((donor) => {
        dataDonor.push({
          donorId: donor?.donorId,
          email: donor?.email,
          sourceOfFunds: donor?.sourceOfFunds,
        });
      });
    }

    const payload = {
      donor: dataDonor,
      klDirectorate: data.klDirectorate,
    };

    return payload;
  };

  const onFinish = (values, nav_btn) => {
    setLoading(true);
    const payload = normalizePayload(values);
    axios
      .put(`${BASE_URL}/proposal/${proposalId}/step/3`, payload)
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err.response.data.errors === undefined ||
          err.response.data.errors.length === 0
        ) {
          errorMessage = err.response.data.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }

        setLoading(false);
        if (nav_btn === 'back') {
          return navigate(
            `/proposal-submission/create-proposal/step/2/${proposalId}`
          );
        } else {
          notification.error({
            message: 'Kesalahan',
            duration: 5,
            description: errorMessage ? errorMessage : errorMessages,
          });
        }
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (nav_btn === 'back') {
            return navigate(
              `/proposal-submission/create-proposal/step/2/${proposalId}`
            );
          } else if (nav_btn === 'list') {
            return navigate(`/proposal-submission`);
          } else {
            return navigate(
              `/proposal-submission/create-proposal/step/4/${proposalId}`
            );
          }
        }
      });
  };

  function onSearchDonor(query) {
    if (query || !query.trim() === '') {
      axios.get(`${BASE_URL}/donor/list?search=${query}`).then((res) => {
        console.log(query);
        console.log(res.data.result);
        setDonors(res.data.result);
      });
    }
  }

  function onClickDonor() {
    axios.get(`${BASE_URL}/donor/list`).then((res) => {
      setDonors(res.data.result);
    });
  }

  useEffect(() => {
    console.log(donors);
  }, [donors]);

  function onChangeDonor(value, obj, idx) {
    let donor = form.getFieldValue('donor');
    donor[idx].donorId = obj.key;
    delete donor[idx].email;

    console.log(obj);

    let _emails = emails;
    _emails[idx] = obj.emails;
    setEmails(_emails);
    form.setFieldValue('donor', donor);
  }

  const onColKLChange = (checked) => {
    setColKL(checked);
  };

  const onColDonorChange = (checked) => {
    setColDonor(checked);
  };

  return (
    <div id="top">
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create-donor"
            onFinish={onFinish}
            initialValues={{
              donor: [{}],
              klDirectorate: [{}],
            }}
          >
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Daftar Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    Ajukan Proposal
                  </span>
                </Button>
              </div>
            </div>
            <div className="mb-4" style={{ marginBottom: '40px' }}>
              <div div className="flex" style={{ marginLeft: '150px' }}>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi General
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi Project
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #00BD52',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Direktorat & Donor
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Dokumen Pendukung
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="warning-box">
              <WarningOutlined style={{ fontSize: 20, marginRight: 10 }} />
              <p>
                Silahkan isi semua data di bawah ini untuk bisa melanjutkan
                pengisian ke tahap selanjutnya
              </p>
            </div>
            <div
              className="w-100 overflow-hidden mb-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">KL/Direktorat</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    onChange={onColKLChange}
                    className="ml-2"
                  />
                </div>
              </div>
              <hr />
              {colKL ? (
                <div className="row px-5 mt-4">
                  <Form.Item
                    shouldUpdate
                    // rules={requiredField}
                  >
                    <Form.List name={['klDirectorate']}>
                      {(klDirectorates, { add, remove }) => (
                        <>
                          {klDirectorates.map((klDirectorate, index) => {
                            return (
                              <Row gutter={12}>
                                <Col span={22}>
                                  <Row>
                                    <Col span={12}>
                                      <div className="">
                                        <label className="font-medium">
                                          Kementerian Lembaga/Negara
                                        </label>
                                        <Form.Item
                                          name={[index, 'kl', 'klName']}
                                          rules={requiredField}
                                        >
                                          <Select
                                            className="mt-2"
                                            type="text"
                                            style={{ width: '98%' }}
                                            options={listKL}
                                            onChange={(value, option) => {
                                              form.setFieldValue(
                                                ['klDirectorate', index, 'kl'],
                                                {
                                                  originalId: option.id,
                                                  code: option.code,
                                                  klName: value,
                                                }
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div className="">
                                        <label className="font-medium">
                                          Direktorat
                                        </label>
                                        <Form.Item
                                          name={[
                                            index,
                                            'directorate',
                                            'directorateName',
                                          ]}
                                          rules={requiredField}
                                        >
                                          <Select
                                            className="mt-2"
                                            type="text"
                                            style={{ width: '98%' }}
                                            options={listDirectorate}
                                            onChange={(value, option) => {
                                              form.setFieldValue(
                                                [
                                                  'klDirectorate',
                                                  index,
                                                  'directorate',
                                                ],
                                                {
                                                  originalId: option.id,
                                                  code: option.code,
                                                  directorateName: value,
                                                }
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                {index > 0 && (
                                  <Col
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <Button
                                        size="large"
                                        style={{
                                          borderRadius: 8,
                                          width: '50px',
                                          height: '48px',
                                          background: '#F04438',
                                          color: '#FFFF',
                                          border: 'none',
                                          marginTop: '12px',
                                        }}
                                        className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                        icon={<DeleteIcon />}
                                        onClick={() => remove(index)}
                                      ></Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            );
                          })}

                          <Space
                            direction="horizontal"
                            style={{
                              width: '100%',
                              justifyContent: 'end',
                              marginLeft: '-20px',
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  height: '52px',
                                  background: '#FFFFFF',
                                  color: '#344054',
                                  border: '1px solid #D0D5DD',
                                }}
                                icon={<PlusOutlined />}
                                className="flex px-2 py-2 pl-4 pr-4 mt-2 justify-center items-center"
                                onClick={() => add()}
                              >
                                <span className="ml-2 font-medium">
                                  Tambah KL/Direktorat
                                </span>
                              </Button>
                            </div>
                          </Space>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="w-100 overflow-hidden mb-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Donor</p>
                  <div className="flex mt-2">
                    <span>
                      (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                    </span>
                    <Switch
                      defaultChecked
                      onChange={onColDonorChange}
                      className="ml-2"
                    />
                  </div>
                </div>
                <hr />
                {colDonor ? (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Form.List name="donor">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, idx) => {
                              return (
                                <div key={field.key} className="px-5">
                                  <Row className="mt-4">
                                    <Col
                                      span={7}
                                      style={{ marginRight: '10px' }}
                                    >
                                      <div className="mr-4">
                                        <label className="font-medium">
                                          Pilih Donor
                                        </label>
                                        <Form.Item name={[idx, 'donor']} rules={requiredField}>
                                          <Select
                                            className="mt-2"
                                            type="text"
                                            style={{ width: '98%' }}
                                            onFocus={onClickDonor}
                                            onSearch={(e) => onSearchDonor(e)}
                                            onChange={(value, obj) => {
                                              onChangeDonor(value, obj, idx);
                                            }}
                                            showSearch
                                            allowClear
                                          >
                                            {donors &&
                                              donors.map((donor) => (
                                                <Option
                                                  key={donor.id}
                                                  value={donor.name}
                                                  emails={donor.emails}
                                                >
                                                  {donor.name}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col
                                      span={8}
                                      style={{ marginRight: '10px' }}
                                    >
                                      <div className="mr-4">
                                        <label className="font-medium">
                                          E-mail
                                        </label>
                                        <Form.Item name={[idx, 'email']} rules={requiredField}>
                                          <Select
                                            className="mt-2"
                                            type="text"
                                            style={{ width: '98%' }}
                                            mode="multiple"
                                            // onSearch={(e) => onSearchEmail(e)}
                                            maxTagCount={2}
                                          >
                                            {emails[idx] &&
                                              emails[idx].map((email) => (
                                                <Option
                                                  key={email}
                                                  value={email}
                                                >
                                                  {email}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col
                                      span={7}
                                      style={{ marginRight: '10px' }}
                                    >
                                      <div className="mr-4">
                                        <label className="font-medium">
                                          Source of Funds
                                        </label>
                                        <Form.Item
                                          name={[idx, 'sourceOfFunds']}
                                          rules={requiredField}
                                        >
                                          <Select
                                            className="mt-2"
                                            type="text"
                                            style={{ width: '98%' }}
                                            options={listSF}
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    {idx !== 0 && (
                                      <Col
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div>
                                          <Button
                                            size="large"
                                            style={{
                                              borderRadius: 8,
                                              width: '50px',
                                              height: '48px',
                                              background: '#F04438',
                                              color: '#FFFF',
                                              border: 'none',
                                              marginTop: '12px',
                                            }}
                                            className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                            icon={<DeleteIcon />}
                                            onClick={() => remove(field.name)}
                                          ></Button>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            <Space
                              direction="horizontal"
                              style={{
                                width: '100%',
                                justifyContent: 'end',
                                marginLeft: '-20px',
                              }}
                            >
                              <div>
                                <Button
                                  style={{
                                    borderRadius: '8px',
                                    height: '52px',
                                    background: '#FFFFFF',
                                    color: '#344054',
                                    border: '1px solid #D0D5DD',
                                  }}
                                  icon={<PlusOutlined />}
                                  className="flex px-2 py-2 pl-4 pr-4 mt-2 justify-center items-center"
                                  onClick={() => add()}
                                >
                                  <span className="ml-2 font-medium">
                                    Tambah Donor
                                  </span>
                                </Button>
                              </div>
                            </Space>
                          </>
                        )}
                      </Form.List>
                    )}
                  </Form.Item>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex justify-end font-medium mb-6 mr-6"></div>
              <hr className="mb-4" />
            </div>

            <div
              className="mt-6 px-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
                position: 'fixed',
                bottom: 0,
                width: 'calc(100vw - 400px)',
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  {/* <NavLink to={`/proposal-submission/create-proposal/step/2/${params.id}`}> */}
                  <Button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #EAECF0',
                      width: '120px',
                      height: '50px',
                    }}
                    icon={<ArrowLeftOutlined />}
                    className="flex justify-center items-center font-medium"
                    onClick={() => {
                      onFinish(form.getFieldsValue(), 'back');
                    }}
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                  {/* </NavLink> */}
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      {/* <NavLink to={'/proposal-submission'}> */}
                      {/* <Button
                        style={{
                          borderRadius: '8px',
                          border: '1px solid #D0D5DD',
                          width: '160px',
                          height: '52px',
                        }}
                        icon={<SaveOutlined />}
                        className="flex justify-center items-center font-medium"
                        onClick={() => {
                          onFinish(form.getFieldsValue(), 'list');
                        }}
                      >
                        <span className="ml-2 text-center">Simpan & Draft</span>
                      </Button> */}
                      {/* </NavLink> */}
                    </Space>
                    <Space>
                      {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                      <Button
                        htmlType="submit"
                        className="btn-submit mt-4 ml-4"
                      >
                        <span className="ml-2 text-center">Selanjutnya</span>
                        <Icon
                          component={ArrowRightOutlined}
                          className="mr-2"
                          style={{ fontSize: 15 }}
                        />
                      </Button>
                      {/* </NavLink> */}
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default CreateProposalSubmissionStepThree;
