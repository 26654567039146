import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Input, InputNumber, Modal, Row, Select } from "antd";
import {
   PlusOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import { OutputForm } from "./OutputForm";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"
import { currencyFormatter, currencyParser } from "utils";

export const OutcomeForm = ({ Form, form, disable, name, priorityProgramIndex, expandIcon, handlePanelChange }) => {
   const { Panel } = Collapse;

   const requiredField = [{ required: true, message: 'Wajib Diisi' }];
   const [listLinkedOutput, setListLinkedOutput] = useState([]);

   useEffect(() => {
      let arr = [];
      let proposal = form.getFieldsValue(true);

      proposal.priorityPrograms?.forEach(priorityProgram => {
         priorityProgram?.impact?.outcomes?.forEach(outcome => {
            outcome.outputs?.forEach(output => {
               arr.push({
                  // ...output,
                  key: output.originalId,
                  value: output.outputName,
                  label: output.outputName
               })
            })
         })
      })

      setListLinkedOutput(arr);
   }, [form.getFieldsValue(true)])

   const handleDeleteOutcome = (remove, idx) => {
      Modal.confirm({
         centered: true,
         title: '',
         icon: <Confirmation />,
         okText: "Confirm",
         bodyStyle: { justifyContent: "center" },
         content: (
            <>
               <h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
                  Anda yakin menghapus Outcome ini?
               </h1>
            </>
         ),
         onOk() {
            remove(idx)
         }
      })
   }

   return (
      <Form.List name={(name === "priorityPrograms") ? [priorityProgramIndex, "impact", "outcomes"] : [priorityProgramIndex, "outcomes"]}>
         {(outcomes, { add, remove }) => (
            <>
               {outcomes.map((outcome, outcomeIndex) => {
                  return (
                     <div key={outcome.key}>
                        <div className="px-4 py-4 mt-4" style={{ border: "1px solid #EAECF0", background: "#FFFF" }}>
                           <Collapse
                              expandIcon={expandIcon}
                              defaultActiveKey={['1']}
                              expandIconPosition="end"
                              onChange={handlePanelChange}
                              className="mt-2 mb-2"
                           >
                              <Panel className="custom-panel pb-4" style={{ color: "black !important" }} header={`Outcome ${outcomeIndex + 1}`} key="1"
                              // extra={(priorityProgramIndex > 0 && !disable) && deletePanel(remove, priorityProgram)}
                              >
                                 <Row gutter={12}>
                                    <Col span={24}>
                                       <div className="">
                                          <label className="font-medium">Outcome</label>&nbsp;
                                          {outcomeIndex > 0 &&
                                             <span><DeleteOutlined onClick={() => { handleDeleteOutcome(remove, outcomeIndex) }} /></span>
                                          }
                                          <Form.Item
                                             name={[outcomeIndex, 'projectOutcome']}
                                             rules={requiredField}
                                          >
                                             <Input
                                                placeholder="Outcome"
                                                type="text"
                                                className="form-input mt-2"
                                                disabled={disable}
                                             />
                                          </Form.Item>

                                          <label className="font-medium">Linkage Output BPDLH</label>
                                          <Form.Item
                                             name={[outcomeIndex, 'linkageOutput', "outputName"]}
                                             rules={requiredField}
                                          >
                                             <Select
                                                placeholder="Output"
                                                type="text"
                                                className="mt-2"
                                                disabled={disable}
                                                options={listLinkedOutput}
                                                onChange={(value, option) => {
                                                   let linkageOutput = {
                                                      originalId: option.key,
                                                      outputName: value
                                                   }

                                                   form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "linkageOutput"], linkageOutput)
                                                }}
                                             />
                                          </Form.Item>
                                       </div>
                                    </Col>
                                 </Row>

                                 <hr style={{ width: "100%" }} className="break-line" />
                                 <div>
                                    <OutputForm
                                       Form={Form}
                                       form={form}
                                       disable={disable}
                                       name={name}
                                       priorityProgramIndex={priorityProgramIndex}
                                       outcomeIndex={outcomeIndex}
                                       expandIcon={expandIcon}
                                       handlePanelChange={handlePanelChange}
                                    />
                                 </div>
                              </Panel>
                           </Collapse>
                        </div>
                     </div>
                  )
               })}

               {!disable &&
                  <div className="flex items-center justify-center">
                     <Button
                        style={{ width: "100%", height: "52px" }}
                        className="btn-default mt-4"
                        disabled={disable}
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                           add({ outcomeName: null, outputs: [] });
                        }}
                     >
                        Tambah Outcome
                     </Button>
                  </div>
               }
            </>
         )
         }
      </Form.List>
   )
}