import React, { createContext, useState, useEffect } from 'react';
import {
  Input,
  Form,
  Button,
  Space,
  Modal,
  notification,
  Spin,
  Row,
  Col,
  Select,
} from 'antd';
import '../index.scss';
import {
  ArrowLeftOutlined,
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { ReactComponent as Confirmation } from '../../../assets/logo/Confirmation.svg';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';
import { useParams, NavLink } from 'react-router-dom';
import { BASE_URL, MASTER_DATA_URL, OIDC } from 'services/config';
import { tagRender } from 'components/atoms/Tag';

const ReachableContext = createContext(null);

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const EditDonor = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const requiredField = [{ required: true, message: 'Wajib Diisi' }];
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [sectors, setSectors] = useState([]);

  let dataEmail = [];
  let phoneNumbers = [];

  const [listThematicProgram, setListThematicProgram] = useState([]);

  const loadListThematicProgram = () => {
    axios
      .get(`${MASTER_DATA_URL}/ThematicProgrammes/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListThematicProgram(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let data;
    axios.get(`${BASE_URL}/donor/${params.id}`).then((res) => {
      setLoading(false);
      data = res.data.result;

      let _sectors = [];

      setSectors(data.sectors);
      data.sectors?.forEach((sector) => {
        _sectors.push(sector.name);
      });
      data.sectors = _sectors;

      form.setFieldsValue(data);
      // dataDonor = { ...data };

      data.emails.forEach((email) => {
        dataEmail.push({
          value: email,
          label: email,
        });
      });
      setEmail(dataEmail);

      data.phoneNumbers.forEach((phoneNumber) => {
        phoneNumbers.push({
          value: phoneNumber,
          label: phoneNumber,
        });
      });
      setPhoneNumber(phoneNumbers);
    });

    loadListThematicProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normalizePayload = (data) => {
    let emails = [];
    let dataPhoneNumbers = [];
    let pic = [];

    if (email && email.length >= 1) {
      email?.forEach((email) => {
        emails.push(email.value);
      });
    }

    if (phoneNumber && phoneNumber.length >= 1) {
      phoneNumber?.forEach((phoneNumber) => {
        dataPhoneNumbers.push(phoneNumber.value);
      });
    }

    if (data.pic) {
      data?.pic.forEach((picData) => {
        pic.push({
          pic: picData.pic,
          address: picData.address,
          phoneNumber: picData.phoneNumber,
          email: picData.email,
        });
      });
    }

    const payload = {
      name: data.name,
      sectors: sectors,
      emails: emails,
      phoneNumbers: dataPhoneNumbers,
      website: data.website,
      organizationName: data.organizationName,
      pic: pic,
      donorName: data.donorName,
      pic_donor: data.pic_donor.map((picDonor) => ({
        pic: picDonor.pic,
        address: picDonor.address,
        phoneNumber: picDonor.phoneNumber,
        email: picDonor.email,
      }))
    };

    return payload;
  };

  const onFinish = (values) => {
    const payload = normalizePayload(values);

    Modal.confirm({
      title: '',
      icon: <Confirmation />,
      okText: 'Confirm',
      bodyStyle: { justifyContent: 'center' },
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: '17px', fontWeight: '600', lineHeight: '24px' }}
          >
            Apakah Anda yakin akan mengubah data ini?
          </h1>
          <br />
        </>
      ),
      onOk() {
        setLoading(true);
        axios
          .put(`${BASE_URL}/donor/${params.id}`, payload)
          .catch((err) => {
            let errorMessage;
            let errorMessages = [];

            if (
              err.response.data.errors === undefined ||
              err.response.data.errors.length === 0
            ) {
              errorMessage = err.response.data.message;
            } else {
              <ul>
                {err.response.data.errors.forEach((error) => {
                  errorMessages.push(<li>{error.message}</li>);
                })}
              </ul>;
            }
            notification.error({
              message: 'Kesalahan',
              duration: 5,
              description: errorMessage ? errorMessage : errorMessages,
            });
            setLoading(false);
          })
          .then((res) => {
            if (res) {
              onDone();
              setLoading(false);
            }
          });
      },
    });
  };

  const onDone = () => {
    Modal.success({
      title: '',
      okText: 'Confirm',
      content: (
        <>
          <h1
            className=""
            style={{
              fontSize: '17px',
              fontWeight: '600',
              lineHeight: '24px',
              textTransform: 'capitalize',
            }}
          >
            Data berhasil diubah!
          </h1>
          <br />
        </>
      ),
      onOk() {
        return navigate('/data-donor');
      },
    });
  };

  function onEmailChange(data) {
    console.log(data);
    setEmail(data);
  }

  function onPhoneNumberChange(data) {
    setPhoneNumber(data);
  }

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create-donor"
            onFinish={onFinish}
            initialValues={{
              pic: [{}],
              pic_donor: [{}],
            }}
          >
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mr-3">Daftar Donor</p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center"
                >
                  <span className="font-medium text-center">Edit Donor</span>
                </Button>
              </div>
            </div>
            <div
              className="w-100 list overflow-hidden mb-6"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Edit Donor</p>
                </div>
                <hr />
                <div className="px-5 gap-x-6 mt-4">
                  <Row>
                    <Col span={12}>
                      <label className="col field-required mb-4 font-medium">
                        Nama Donor
                      </label>
                      <Form.Item name="name" rules={requiredField}>
                        <Input
                          placeholder="Nama Donor"
                          type="text"
                          className="form-input mt-4"
                          style={{ width: '98%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <label className="col-4 field-required font-medium">
                        Program Tematik
                      </label>
                      <Form.Item name={'sectors'} rules={requiredField}>
                        <Select
                          placeholder="Program Tematik"
                          className="mt-4"
                          type="text"
                          mode="multiple"
                          tagRender={tagRender}
                          style={{ width: '99%' }}
                          options={listThematicProgram}
                          onChange={(value, option) => {
                            let _sectors = [];
                            value.forEach((item, idx) => {
                              _sectors.push({
                                originalId: option[idx].id,
                                name: item,
                              });
                            });
                            setSectors(_sectors);
                          }}
                          maxTagCount="responsive"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="px-5 gap-x-6">
                  <Row>
                    <Col span={12}>
                      <label className="col font-medium">
                        Email (Optional)
                      </label>
                      <Form.Item name="emailsDonor">
                        <div style={{ width: '98%' }}>
                          <CreatableSelect
                            placeholder="Email"
                            isClearable
                            className="mt-4"
                            isMulti
                            defaultValue={dataEmail}
                            onChange={(e) => onEmailChange(e)}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <label className="col font-medium">
                        No Telepon (Optional)
                      </label>
                      <Form.Item name="phoneNumbersDonor">
                        <div style={{ width: '99%' }}>
                          <CreatableSelect
                            isClearable
                            placeholder="No Telepone"
                            className="mt-4"
                            isMulti
                            defaultValue={phoneNumbers}
                            onChange={(e) => onPhoneNumberChange(e)}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="row px-5">
                  <div className="">
                    <label className="font-medium">Website (Optional)</label>
                    <Form.Item
                      name="website"
                      rules={[
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Website"
                        type="text"
                        className="form-input mt-4"
                      />
                    </Form.Item>
                  </div>
                </div>
                <hr className="mb-4" />
              </div>
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium mb-2">
                    Accredited Entity (Optional)
                  </p>
                  <span className="text-slate-600">Informasi Organisasi</span>
                </div>
                <hr />
                <div className="row px-5 py-5">
                  <div className="w-100">
                    <div className="">
                      <label className="font-medium">Nama Organisasi</label>
                      <Form.Item name="organizationName">
                        <Input
                          placeholder="Nama Organisasi"
                          type="text"
                          className="form-input mt-4"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <Form.List name="pic">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <div className="px-5">
                                <Row>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">PIC</label>
                                      <Form.Item name={[idx, 'pic']}>
                                        <Input
                                          placeholder="PIC"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        Alamat
                                      </label>
                                      <Form.Item name={[idx, 'address']}>
                                        <Input
                                          placeholder="Alamat"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        No Telepon
                                      </label>
                                      <Form.Item
                                        name={[idx, 'phoneNumber']}
                                        rules={[
                                          {
                                            pattern: /^(?:\d*)$/,
                                            message:
                                              'Silahkan input nomor telepon yang valid',
                                          },
                                          {
                                            pattern: /^[\d]{5,15}$/,
                                            message:
                                              'Tidak kurang dari 5 dan tidak melebihi 15 karakter',
                                          },
                                        ]}
                                        validateTrigger="onChange"
                                      >
                                        <Input
                                          placeholder="No Telephone"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        Email
                                      </label>
                                      <Form.Item
                                        name={[idx, 'email']}
                                        rules={[
                                          {
                                            type: 'email',
                                            message:
                                              'Silahkan masukan email yang valid',
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Email"
                                          type="email"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <Button
                                        size="large"
                                        style={{
                                          borderRadius: '2px',
                                          width: '50px',
                                          height: '48px',
                                          background: '#F04438',
                                          color: '#FFFF',
                                          border: 'none',
                                          marginTop: '12px',
                                        }}
                                        className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                        icon={<DeleteOutlined />}
                                        onClick={() => remove(field.name)}
                                      ></Button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Space
                            direction="horizontal"
                            style={{
                              width: '100%',
                              justifyContent: 'end',
                              marginLeft: '-20px',
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  height: '40px',
                                  background: '#EEFFF4',
                                  color: '#01A24A',
                                  border: 'none',
                                }}
                                icon={<PlusOutlined />}
                                className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                onClick={add}
                              >
                                <span className="ml-2 font-medium">
                                  Tambah PIC lain
                                </span>
                              </Button>
                            </div>
                          </Space>
                        </>
                      )}
                    </Form.List>
                  )}
                </Form.Item>
              </div>
              <hr className="mb-4" />
            </div>
            <div
              className="w-100 list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium mb-2">
                    PIC Donor
                  </p>
                  <span className="text-slate-600">Informasi Donor</span>
                </div>
                <hr />
                <div className="row px-5 py-5">
                  <div className="w-100">
                    <div className="">
                      <label className="font-medium">Nama Donor</label>
                      <Form.Item name="donorName">
                        <Input
                          placeholder="Nama Donor"
                          type="text"
                          className="form-input mt-4"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <Form.List name="pic_donor">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <div className="px-5">
                                <Row>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">PIC</label>
                                      <Form.Item name={[idx, 'pic']}>
                                        <Input
                                          placeholder="PIC"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        Alamat
                                      </label>
                                      <Form.Item name={[idx, 'address']}>
                                        <Input
                                          placeholder="Alamat"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        No Telepon
                                      </label>
                                      <Form.Item
                                        name={[idx, 'phoneNumber']}
                                        rules={[
                                          {
                                            pattern: /^(?:\d*)$/,
                                            message:
                                              'Silahkan input nomor telepon yang valid',
                                          },
                                          {
                                            pattern: /^[\d]{5,15}$/,
                                            message:
                                              'Tidak kurang dari 5 dan tidak melebihi 15 karakter',
                                          },
                                        ]}
                                        validateTrigger="onChange"
                                      >
                                        <Input
                                          placeholder="No Telephone"
                                          type="text"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={5} style={{ marginRight: '10px' }}>
                                    <div className="mr-4">
                                      <label className="font-medium">
                                        Email
                                      </label>
                                      <Form.Item
                                        name={[idx, 'email']}
                                        rules={[
                                          {
                                            type: 'email',
                                            message:
                                              'Silahkan masukan email yang valid',
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Email"
                                          type="email"
                                          className="form-input mt-4"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <Button
                                        size="large"
                                        style={{
                                          borderRadius: '2px',
                                          width: '50px',
                                          height: '48px',
                                          background: '#F04438',
                                          color: '#FFFF',
                                          border: 'none',
                                          marginTop: '12px',
                                        }}
                                        className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                        icon={<DeleteOutlined />}
                                        onClick={() => remove(field.name)}
                                      ></Button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Space
                            direction="horizontal"
                            style={{
                              width: '100%',
                              justifyContent: 'end',
                              marginLeft: '-20px',
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  height: '40px',
                                  background: '#EEFFF4',
                                  color: '#01A24A',
                                  border: 'none',
                                }}
                                icon={<PlusOutlined />}
                                className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                onClick={add}
                              >
                                <span className="ml-2 font-medium">
                                  Tambah PIC lain
                                </span>
                              </Button>
                            </div>
                          </Space>
                        </>
                      )}
                    </Form.List>
                  )}
                </Form.Item>
              </div>
              <hr className="mb-4" />
            </div>
            <div
              className="list mt-6 px-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  <NavLink to={'/data-donor'}>
                    <Button
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #EAECF0',
                        width: '120px',
                        height: '50px',
                      }}
                      icon={<ArrowLeftOutlined />}
                      className="flex justify-center items-center font-medium"
                    >
                      <span className="ml-2 text-center">Kembali</span>
                    </Button>
                  </NavLink>
                </Space>
                <Form.Item>
                  <ReachableContext.Provider value="Light">
                    <Space>
                      <Button
                        style={{
                          background: '#00BD52',
                          color: '#FFFF',
                          borderRadius: '8px',
                          width: '120px',
                          height: '50px',
                        }}
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        className="flex justify-center items-center font-medium mt-4"
                      >
                        <span className="ml-2 text-center">Simpan</span>
                      </Button>
                    </Space>
                  </ReachableContext.Provider>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default EditDonor;
